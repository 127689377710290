var site = site || {};

(function ($) {
  Drupal.behaviors.tabbedProductsBlockV2 = {
    attach: function (context) {
      var $modules = $('.js-tabbed-products-block-v2', context);
      var $grids = $('.js-product-grid', $modules);
      var $carousels = $('.js-product-brief-carousel-items', $grids);

      $modules.each(function () {
        var $module = $(this);
        var $trigger = $('.js-tabbed-products-block-trigger', $module);
        var $content = $('.js-tabbed-products-block-content', $module);

        $trigger.off('click.tabbedBlock').on('click.tabbedBlock', function (event) {
          event.preventDefault();
          var index = $(this).index();

          switchToIndex(index);
        });

        function switchToIndex(index) {
          var $activeContent = $content.eq(index);

          // Show this tab content
          $content.removeClass('current');
          $activeContent.addClass('current');
          $trigger.removeClass('current');
          $trigger.eq(index).addClass('current');
        }
      });
      $carousels.each(function () {
        var $this = $(this);
        var $arrowsDiv = $this.parent().find('.js-tabbed-products-block__carousel-arrows');
        var $dotsDiv = $this.parent().find('.js-tabbed-products-block__carousel-dots');
        var $grid = $this.closest($grids);
        var slides_to_show = JSON.parse($grid.attr('data-grid-items-per-row'));
        var isRTL = site.direction.isRTL && Drupal?.settings?.forceSlickRTL;
        var settings = {
          appendArrows: $arrowsDiv,
          appendDots: $dotsDiv,
          swipe: true,
          draggable: true,
          rtl: isRTL,
          infinite: true,
          slidesToShow: slides_to_show.large,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows: true,
                dots: false,
                slidesToShow: slides_to_show.medium,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 769,
              settings: {
                arrows: false,
                dots: true,
                focusOnSelect: true,
                slidesToShow: slides_to_show.small,
                slidesToScroll: 1
              }
            }
          ],
          onInit: function (slick) {
            // Get copy of original swipeEnd function
            var ogSwipeEnd = slick.swipeEnd;

            // Override function
            slick.swipeEnd = function (e) {
              // Call og function
              ogSwipeEnd.call(slick, e);
              // Remove click.slick event once swipe is done
              $(e.target).off('click.slick');
            };
          }
        };

        // Init this carousel with our settings
        $this.slick(settings);
        $grid.trigger('slider.loaded');
      });
    }
  };
})(jQuery);
